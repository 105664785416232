import React from 'react';
import { Route } from 'react-router';

import { useAuth } from '../auth';
import Error403 from '../statuses/Error403';

export default function PrivateRoute({ component: Component, id, ...other }) {
    const { authenticated } = useAuth();
    if (!authenticated) {
        return <Error403 id={`${id}Error403`} />;
    }
    return <Route {...other} render={() => <Component id={id} />} />;
}
