import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

if (window.REACT_APP_PUBLIC_API_URL) {
    localStorage.setItem('publicApiUrl', window.REACT_APP_PUBLIC_API_URL);
} else {
    window.REACT_APP_PUBLIC_API_URL = localStorage.getItem('publicApiUrl');
}

ReactDOM.render(<App />, document.getElementById('root'));

// Remove existing service worker, NB! Can be removed afterwards
if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function unRegisterAll(registrations) {
        // eslint-disable-next-line no-restricted-syntax
        for (const registration of registrations) {
            registration.unregister();
        }
    });
}
