import { hasAll } from 'cleveron-web-components/utils';
import { is4XX } from 'cleveron-web-components/utils/apmTypeValidator';

const initialState = {
    apmId: 0,
    commandError: null,
    commandLoading: false,
    consoles: [],
    isMultiSidedLocker: false,
    layoutError: null,
    layoutLoading: false,
    selectedSlots: [],
    slotsCount: 0,
    slotsStateUpdatedAt: null,
    towers: [],
};

function reducer(state = initialState, action) {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
        return initialState;
    }

    if (action.type === 'LAYOUT_REQUEST') {
        if (action.payload.apmId === state.apmId) {
            return {
                ...state,
                commandError: null,
                layoutError: null,
                layoutLoading: true,
                selectedSlots: [],
            };
        }
        return { ...initialState, layoutLoading: true };
    }

    if (action.type === 'LAYOUT_FAILURE') {
        return {
            ...initialState,
            layoutError:
                (action.payload.response &&
                    action.payload.response.data &&
                    action.payload.response.data.code &&
                    `error.${action.payload.response.data.code}`) ||
                action.payload.message,
        };
    }

    if (action.type === 'LAYOUT_SUCCESS') {
        return {
            ...state,
            apmId: action.payload.apmId,
            commandError: null,
            consoles: action.payload.consoleList,
            isMultiSidedLocker:
                [...new Set([...action.payload.towerList.map(tower => tower.side)])].length > 1,
            layoutError: null,
            layoutLoading: false,
            slotsCount: action.payload.slotsCount,
            slotsStateUpdatedAt: action.payload.slotsStateUpdatedAt,
            towers: action.payload.towerList.map(({ consoleList, id, slotList, ...newTower }) => ({
                ...newTower,
                consoles: consoleList,
                id,
                slots: slotList.map(slot => ({
                    ...slot,
                    filled: slot.itemsOnSlot >= slot.maxItemsOnSlot,
                    partiallyFilled: slot.itemsOnSlot && slot.itemsOnSlot < slot.maxItemsOnSlot,
                    stateId: slot.stateDetails?.stateId,
                    ...(is4XX(action.payload.apmTypeId) && {
                        slotTitle: null,
                    }),
                })),
            })),
        };
    }

    if (action.type === 'OPEN_SLOTS_REQUEST' || action.type === 'SAVE_SLOT_STATUS_REQUEST') {
        return {
            ...state,
            commandError: null,
            commandLoading: true,
            layoutError: null,
            selectedSlots: [],
        };
    }

    if (action.type === 'OPEN_SLOTS_FAILURE' || action.type === 'SAVE_SLOT_STATUS_FAILURE') {
        return {
            ...state,
            commandError:
                (action.payload.response &&
                    action.payload.response.data &&
                    action.payload.response.data.code &&
                    `error.${action.payload.response.data.code}`) ||
                action.payload.message,
            commandLoading: false,
            layoutError: null,
            selectedSlots: [],
        };
    }

    if (action.type === 'OPEN_SLOTS_SUCCESS' || action.type === 'SAVE_SLOT_STATUS_SUCCESS') {
        return {
            ...state,
            commandError: null,
            commandLoading: false,
            layoutError: null,
            selectedSlots: [],
        };
    }

    if (
        action.type === 'SAVE_RESERVED_SLOTS_REQUEST' ||
        action.type === 'SAVE_GROUPED_SLOTS_REQUEST' ||
        action.type === 'SAVE_MAILROOM_SLOTS_REQUEST'
    ) {
        return { ...state, selectedSlots: [] };
    }

    if (action.type === 'DESELECTED_ALL_SLOTS') {
        return { ...state, commandError: null, layoutError: null, selectedSlots: [] };
    }

    if (action.type === 'SELECTED_SLOTS') {
        return {
            ...state,
            commandError: null,
            layoutError: null,
            selectedSlots: hasAll(state.selectedSlots, action.payload)
                ? state.selectedSlots.filter(slotId => !action.payload.includes(slotId))
                : [
                      ...state.selectedSlots,
                      ...action.payload.filter(slotId => !state.selectedSlots.includes(slotId)),
                  ],
        };
    }

    return state;
}

export default reducer;
