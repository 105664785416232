const initialState = '';

function reducer(state = initialState, action) {
    if (action.type === 'VERSION_SUCCESS') {
        return `v${action.payload.version}`;
    }

    return state;
}

export default reducer;
