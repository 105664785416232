import { AppWrapper, LoadingPage, withJob } from 'cleveron-web-components';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import { fetchMe as fetchMeAction } from '../../actions/me';
import { fetchPrivacyPolicy as fetchPrivacyPolicyAction } from '../../actions/privacyPolicy';
import { routeChanged as routeChangedAction } from '../../actions/route';
import { fetchVersion as fetchVersionAction } from '../../actions/version';
import ConnectedAppDrawer from '../ConnectedAppDrawer';
import Routes from '../Routes';
import Error408 from '../statuses/Error408';

function ConnectedAppWrapper({ id, me, routeChanged }) {
    const location = useLocation();
    useEffect(() => {
        routeChanged(location.pathname);
    }, [location.pathname, routeChanged]);

    if (!me.user && me?.error?.message === 'Network Error') {
        return <Error408 id={`${id}Error408`} route={`${location.pathname}${location.search}`} />;
    }

    return (
        <AppWrapper ConnectedAppDrawer={ConnectedAppDrawer} drawer={!!me.user} id={id}>
            <Routes id={`${id}Route`} />
        </AppWrapper>
    );
}

function mapStateToProps({ me }) {
    return { me };
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchMe: fetchMeAction,
            fetchPrivacyPolicy: fetchPrivacyPolicyAction,
            fetchVersion: fetchVersionAction,
            routeChanged: routeChangedAction,
        },
        dispatch
    );

export default compose(
    connect(null, mapDispatchToProps),
    withJob({
        LoadingComponent: LoadingPage,
        work: ({ fetchMe, fetchPrivacyPolicy, fetchVersion }) =>
            Promise.all([fetchPrivacyPolicy(), fetchVersion(), fetchMe()]),
    })
)(connect(mapStateToProps)(ConnectedAppWrapper));
