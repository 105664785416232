const initialState = {
    clientId: null,
};

function reducer(state = initialState, action) {
    if (action.type === 'CLIENT_CHANGE_SUCCESS') {
        return action.payload;
    }

    return state;
}

export default reducer;
