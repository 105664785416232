import Axios, { CancelToken } from 'axios';
import { find } from 'lodash';

import { keycloak } from '../App/auth';

const sources = [];

function apiRequest({ actionName, actionParams, payload, requestActionProps, requestParams }) {
    return {
        actionParams,
        payload,
        requestActionProps,
        requestParams,
        type: `${actionName}_REQUEST`,
    };
}

function apiSuccess({ actionName, actionParams, payload, requestParams, snackbarProps }) {
    return { actionParams, payload, requestParams, snackbarProps, type: `${actionName}_SUCCESS` };
}

function apiFailure({ actionName, actionParams, payload, requestParams }) {
    return { actionParams, payload, requestParams, type: `${actionName}_FAILURE` };
}

function requestFailure({ actionName, requestParams }) {
    return { requestParams, type: `${actionName}_REQUEST_FAILURE` };
}

/*
    To avoid BroadcastChannel errors we shouldn't pass functions to state synced actions.
    See stateSyncConfig in src/redux/configureStore.js
 */
const stateSyncActions = ['AUTHENTICATE', 'LOGOUT', 'ME'];

export function onSubmitFail({ error }, dispatch) {
    if (error) {
        dispatch({
            payload: {
                message: !error.message?.id ? { id: 'error.11' } : error.message,
            },
            type: 'FORM_SUBMIT_FAILURE',
        });
    }
}

export default function apiRequestActionCreator(props) {
    const {
        actionName,
        actionParams,
        blockParallel,
        params,
        processError = error => error,
        processSuccessResponse = ({ data }) => data,
        requestActionProps = {},
        rethrow = false,
        successSnackbarProps,
    } = props;
    return async (dispatch, getState, { axios }) => {
        if (blockParallel && sources[actionName]) {
            sources[actionName].cancel(actionName);
        }
        sources[actionName] = CancelToken.source();

        const requestParams = {
            cancelToken: !stateSyncActions.includes(actionName) && sources[actionName].token,
            method: 'get',
            withCredentials: true,
            ...params,
        };
        if (find(getState().requests.request, requestParams)) {
            return Promise.resolve(true);
        }

        dispatch(
            apiRequest({
                actionName,
                actionParams,
                payload: props,
                requestActionProps,
                requestParams,
            })
        );
        try {
            await keycloak.updateToken(5);
            const response = await axios.request({
                ...requestParams,
                headers: {
                    ...params.headers,
                    Authorization: `Bearer ${keycloak.token}`,
                },
            });
            const payload = processSuccessResponse(response, params, dispatch);
            await dispatch(
                apiSuccess({
                    actionName,
                    actionParams,
                    payload,
                    requestParams,
                    snackbarProps: successSnackbarProps,
                })
            );
            return payload;
        } catch (error) {
            if (Axios.isCancel(error)) {
                return error;
            }
            if (error.response?.status === 401) {
                keycloak.logout();
            }
            await dispatch(requestFailure({ actionName, requestParams }));
            const payload = processError(error, dispatch);
            await dispatch(apiFailure({ actionName, actionParams, payload, requestParams }));
            if (rethrow) {
                throw payload;
            }

            return payload;
        }
    };
}
