import { VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { StatusPage } from 'cleveron-web-components';
import React from 'react';
import { defineMessage } from 'react-intl';

function Error403() {
    return (
        <StatusPage
            buttonLink="/"
            buttonTextKey={
                defineMessage({
                    defaultMessage: 'Go to front page',
                    id: 'error.action.goToFrontPage',
                }).id
            }
            Icon={VisibilityOffIcon}
            textBottomKey={
                defineMessage({
                    defaultMessage: 'Please check with your system administrator.',
                    id: 'error.accessRequired.helper.secondary',
                }).id
            }
            textTopKey={
                defineMessage({
                    defaultMessage: 'We could not find your access permissions to this page.',
                    id: 'error.accessRequired.helper.primary',
                }).id
            }
            titleKey={
                defineMessage({
                    defaultMessage: 'Access required',
                    id: 'error.accessRequired.title',
                }).id
            }
        />
    );
}

export default Error403;
