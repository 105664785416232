/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchMe() {
    return apiRequestActionCreator({
        actionName: 'ME',
        params: {
            url: '/stateful/v2/me',
        },
    });
}
