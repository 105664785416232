import { Providers } from 'cleveron-web-components';
import React from 'react';
import { connect } from 'react-redux';

import translations from '../../translations';
import AuthContextProvider from '../auth';
import ConnectedAppWrapper from '../ConnectedAppWrapper';

function ConnectedProviders({ darkMode, locale, id }) {
    return (
        <Providers
            darkMode={darkMode}
            IntlProviderProps={{
                onError: err => process.env.NODE_ENV !== 'production' && console.error(err),
            }}
            locale={locale}
            translations={translations}
        >
            <AuthContextProvider>
                <ConnectedAppWrapper id={id} />
            </AuthContextProvider>
        </Providers>
    );
}

function mapStateToProps({ darkMode, locale }) {
    return { darkMode, locale };
}

export default connect(mapStateToProps)(ConnectedProviders);
