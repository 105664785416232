import { OpenInNew as OpenInNewIcon, PhoneAndroid as PhoneAndroidIcon } from '@mui/icons-material';
import { Divider, ListSubheader, MenuList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MainMenuLink } from 'cleveron-web-components';
import { hasOne } from 'cleveron-web-components/utils';
import { capitalize, startCase } from 'lodash';
import { parse, stringify } from 'query-string';
import React from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { ADMINISTRATOR_PATHS, PERMISSIONS, SUPERUSER_PATHS, USER_PATHS } from '../../constants';
import usePermissions from '../usePermissions';
import { MainMenuGroup, MainMenuGroupHyperLinkItem } from './MainMenuGroup';

const useStyles = makeStyles(theme => ({
    clientName: {
        '&:focus': {
            outline: 'none',
        },
        color: theme.palette.primary.contrastText,
        display: 'block',
        height: 34,
        opacity: 0.7,
        overflow: 'hidden',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
    },
    divider: {
        backgroundColor: theme.palette.primary.contrastText,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        opacity: 0.34,
    },
    root: {
        flex: 99,
        overflowY: 'auto',
    },
}));

function ApiDocSubMenu({ id, clientId }) {
    const classes = useStyles();
    const intl = useIntl();
    const apiDocumentsBaseUrl = `${
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_PUBLIC_API_URL
            : window.REACT_APP_PUBLIC_API_URL
    }/stateful/v1/clients/${clientId}/apidocs`;

    return (
        <>
            <Divider key="api-docs" className={classes.divider} />
            <MainMenuGroup
                Icon={OpenInNewIcon}
                id={id}
                items={[
                    <MainMenuGroupHyperLinkItem
                        href={`${apiDocumentsBaseUrl}/integration`}
                        id={`${id}Integration`}
                        label={intl.formatMessage({
                            defaultMessage: 'Integration',
                            id: 'page.integrationApi',
                        })}
                    />,
                    <MainMenuGroupHyperLinkItem
                        href={`${apiDocumentsBaseUrl}/mobile`}
                        id={`${id}Mobile`}
                        label={intl.formatMessage({
                            defaultMessage: 'Mobile',
                            id: 'page.mobileApi',
                        })}
                    />,
                    <MainMenuGroupHyperLinkItem
                        href={`${apiDocumentsBaseUrl}/cleveron354`}
                        id={`${id}Cleveron354`}
                        label={intl.formatMessage({
                            defaultMessage: 'Cleveron 354',
                            id: 'page.cleveron354Api',
                        })}
                    />,
                ]}
                label={intl.formatMessage({
                    defaultMessage: 'API documents',
                    id: 'page.apiDocuments',
                })}
            />
        </>
    );
}

function DownloadCourierApp({ id, clientId, closeMenu }) {
    const classes = useStyles();

    const translation = defineMessage({
        defaultMessage: 'Courier App',
        id: 'page.downloadCourierApp',
    });
    return (
        <>
            <Divider key="courier-app-divider" className={classes.divider} />
            <MenuList key="/apidocs/cleveron354" id={`${id}List`}>
                <MainMenuLink
                    key="/apidocs/cleveron354"
                    closeMenu={closeMenu}
                    href={`${
                        process.env.NODE_ENV === 'development'
                            ? process.env.REACT_APP_PUBLIC_API_URL
                            : window.REACT_APP_PUBLIC_API_URL
                    }/stateful/v1/clients/${clientId}/downloadcourierapk`}
                    icon={PhoneAndroidIcon}
                    id={`${id}${translation.id}`}
                    textKey={translation.id}
                />
            </MenuList>
        </>
    );
}

function MainMenu({ authorities, closeMenu, id, user }) {
    const classes = useStyles();
    const location = useLocation();
    const { isSuperuser, isAdministrator } = usePermissions();

    const [, basePath, clientIdString, clientCollectionPath] = location.pathname.split('/');
    const clientId = basePath === 'clients' && parseInt(clientIdString, 10);
    const authority = authorities[clientId];
    const permissionsForClient = Object.keys(authority?.permissions || {}).map(permission =>
        Number(permission)
    );
    const clientName = authority?.client?.name;
    const officeConfig = authority?.config || {};

    const search = stringify({ hl: parse(location.search).hl });

    return (
        <div key="main-menu" className={classes.root} id={id}>
            {isSuperuser && (
                <MenuList>
                    {SUPERUSER_PATHS.map(({ path: pathname, icon, translation }) => {
                        return (
                            <MainMenuLink
                                key={pathname}
                                closeMenu={closeMenu}
                                icon={icon}
                                id={`${id}${capitalize(startCase(pathname))}Button`}
                                isMatch={!clientCollectionPath && basePath === pathname}
                                textKey={translation.id}
                                to={{ pathname: `/${pathname}`, search }}
                            />
                        );
                    })}
                    {!!clientId && (
                        <ListSubheader
                            className={classes.clientName}
                            component={Link}
                            disableSticky
                            id={`${id}ClientButton`}
                            to={`/clients/${clientId}`}
                        >
                            {clientName}
                        </ListSubheader>
                    )}
                </MenuList>
            )}
            {isAdministrator && user?.privacyPolicyAccepted && (
                <MenuList>
                    {ADMINISTRATOR_PATHS.filter(
                        ({ permissions }) =>
                            !permissions ||
                            hasOne(
                                permissions,
                                Object.values(
                                    Object.values(authorities)?.[0]?.permissions || {}
                                ).map(({ permissionId }) => permissionId)
                            )
                    ).map(({ icon, path: pathname, translation }) => {
                        return (
                            <MainMenuLink
                                key={pathname}
                                closeMenu={closeMenu}
                                icon={icon}
                                id={`${id}${capitalize(startCase(pathname))}Button`}
                                isMatch={!clientCollectionPath && basePath === pathname}
                                textKey={translation.id}
                                to={{ pathname: `/${pathname}`, search }}
                            />
                        );
                    })}
                    {!!clientId && (
                        <ListSubheader
                            className={classes.clientName}
                            disableSticky
                            id={`${id}ClientName`}
                        >
                            {clientName}
                        </ListSubheader>
                    )}
                </MenuList>
            )}
            {USER_PATHS.reduce((acc, pathPermission, key) => {
                const paths = pathPermission
                    .filter(
                        ({ clientOfficeConfigPredicate }) =>
                            !clientOfficeConfigPredicate ||
                            clientOfficeConfigPredicate(officeConfig)
                    )
                    .reduce(
                        (
                            accc,
                            { href, icon, mainMenuProps, path, permissions, target, translation }
                        ) => {
                            if (
                                hasOne(permissions, permissionsForClient) &&
                                !mainMenuProps?.hidden
                            ) {
                                return [
                                    ...accc,
                                    {
                                        href,
                                        icon,
                                        mainMenuProps,
                                        path: `/clients/${clientId}/${path}`,
                                        target,
                                        translation,
                                    },
                                ];
                            }
                            return accc;
                        },
                        []
                    );
                if (paths.length) {
                    return [
                        ...acc,
                        (key !== 0 || isSuperuser) && (
                            <Divider key={`${paths[0].path}-divider`} className={classes.divider} />
                        ),
                        <MenuList
                            key={paths[0].path}
                            id={`${id}${paths[0].textKey || paths[0].path.split('/')[3]}List`}
                        >
                            {paths.map(
                                ({ href, icon, path, target, translation, mainMenuProps }) => (
                                    <MainMenuLink
                                        key={path}
                                        closeMenu={closeMenu}
                                        href={href}
                                        icon={icon}
                                        id={`${id}${translation.id}`}
                                        isMatch={
                                            mainMenuProps?.isMatch
                                                ? mainMenuProps.isMatch(location.pathname)
                                                : location.pathname.split('/')[3] ===
                                                  path.split('/')[3]
                                        }
                                        target={target}
                                        textKey={translation.id}
                                        to={{ pathname: path, search }}
                                    />
                                )
                            )}
                        </MenuList>,
                    ];
                }
                return acc;
            }, [])}
            {permissionsForClient.includes(PERMISSIONS.DOC_VIEW) && (
                <ApiDocSubMenu clientId={clientId} id={`${id}ApiDoc`} />
            )}
            {permissionsForClient.includes(PERMISSIONS.COURIER_APP_DOWNLOAD) && (
                <DownloadCourierApp clientId={clientId} id={`${id}DownloadCourierApp`} />
            )}
        </div>
    );
}

export default MainMenu;
