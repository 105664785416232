/* eslint-disable import/prefer-default-export */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import XLSX from 'xlsx';

import { SubmissionError } from '../form';
import apiRequestActionCreator from './apiRequestActionCreator';

const defaultTimeout = 60 * 1000;

export function emptyCollection({ collectionActionName }) {
    return () => dispatch => dispatch({ type: `${collectionActionName}_EMPTY` });
}

export function fetchCollection({
    apiCollectionName,
    apiVersion,
    collectionActionName,
    useClientPrefix = true,
    paramsFunction,
}) {
    return ({ clientId, page, ...other } = {}) => {
        const defaultParams = { ...other, offset: page - 1 || 0 };
        return apiRequestActionCreator({
            actionName: collectionActionName,
            params: {
                params: paramsFunction ? paramsFunction({ params: defaultParams }) : defaultParams,
                url: useClientPrefix
                    ? `/stateful/${apiVersion || 'v1'}/clients/${clientId}/${apiCollectionName}`
                    : `/stateful/${apiVersion || 'v1'}/${apiCollectionName}`,
            },
        });
    };
}

// If API fails to pass key
export function getTranslationByMessage(message) {
    if (message) {
        const betweenValidation = message.match(/Size must be between (\d+) and (\d+)/);
        if (betweenValidation) {
            const [, min, max] = betweenValidation;
            return (
                <FormattedMessage
                    defaultMessage="Size must be between {min} and {max}"
                    id="validation.betweenValues"
                    values={{ max, min }}
                />
            );
        }

        const maxValueValidation = message.match(/Value is longer than (\d+) characters/);
        if (maxValueValidation) {
            const [, max] = maxValueValidation;
            return (
                <FormattedMessage
                    defaultMessage="Max. length {max}"
                    id="validation.maxLength"
                    values={{ max }}
                />
            );
        }

        const maxLengthExceededValidation = message.match(/Value must be less than (\d+)/);
        if (maxLengthExceededValidation) {
            const [, max] = maxLengthExceededValidation;
            return (
                <FormattedMessage
                    defaultMessage="Value must be less than {value}"
                    id="validation.maxValue"
                    values={{ value: max }}
                />
            );
        }

        const notNullValidation = message.match(/Value must not be null/);
        if (notNullValidation) {
            return <FormattedMessage defaultMessage="Required" id="validation.required" />;
        }

        const minValueNotMet = message.match(/Value must be at least (\d+)/);
        if (minValueNotMet) {
            const [, min] = minValueNotMet;
            return (
                <FormattedMessage
                    defaultMessage="Value must be atleast {value}"
                    id="validation.minValue"
                    values={{ value: min }}
                />
            );
        }

        const invalidEmail = message.match(/Email should be valid/);
        if (invalidEmail) {
            return <FormattedMessage defaultMessage="Invalid Email" id="validation.invalidEmail" />;
        }

        const invalidPhone = message.match(/Phone should be valid/);
        if (invalidPhone) {
            return (
                <FormattedMessage
                    defaultMessage="Invalid Phone number"
                    id="validation.invalidPhone"
                />
            );
        }

        const failedToUpdate = message.match(
            /System tries to update parcel \(.*\), that is already being received/
        );

        if (failedToUpdate) {
            return (
                <FormattedMessage
                    defaultMessage="Already received"
                    id="validation.alreadyReceived"
                />
            );
        }

        const failedToParse = message.match(/'(.+)' could not be parsed at index/);

        if (failedToParse) {
            const [, what] = failedToParse;

            return (
                <>
                    <FormattedMessage defaultMessage="Invalid data" id="validation.invalidData" />
                    {` ${what}`}
                </>
            );
        }

        if (message === 'Wrong service') {
            return <FormattedMessage defaultMessage="Wrong service" id="validation.wrongService" />;
        }

        if (message === 'Wrong type') {
            return <FormattedMessage defaultMessage="Wrong service" id="validation.wrongType" />;
        }

        if (
            message === 'Unknown destination.apmId' ||
            message === 'Unknown destination.apm' ||
            message === 'Wrong apm'
        ) {
            return <FormattedMessage defaultMessage="No such APM" id="validation.noSuchApm" />;
        }

        if (message === 'Wrong date format' || message === 'Wrong date format.') {
            return <FormattedMessage defaultMessage="Invalid date" id="validation.invalidDate" />;
        }

        if (
            message === 'Unknown destination.locationId' ||
            message === 'Unknown destination.location'
        ) {
            return (
                <FormattedMessage
                    defaultMessage="No such location"
                    id="validation.noSuchApmLocation"
                />
            );
        }

        if (message === 'Unknown slot size') {
            return (
                <FormattedMessage
                    defaultMessage="No such slot size"
                    id="validation.noSuchSlotSize"
                />
            );
        }

        if (message === 'Unknown company') {
            return (
                <FormattedMessage defaultMessage="No such company" id="validation.noSuchCompany" />
            );
        }

        if (message === 'invalid slot group externalId') {
            return (
                <FormattedMessage
                    defaultMessage="No such slot group"
                    id="validation.noSuchSlotGroup"
                />
            );
        }

        if (message === 'There is more than one order with such barcode') {
            return (
                <FormattedMessage
                    defaultMessage="More than one order with such barcode"
                    id="validation.duplicateBarcode"
                />
            );
        }

        if (message === 'Order templates list is invalid (invalid id or duplicate)') {
            return (
                <FormattedMessage
                    defaultMessage="Template invalid"
                    id="validation.invalidTemplate"
                />
            );
        }

        if (message === 'Unknown temperature zone') {
            return (
                <FormattedMessage
                    defaultMessage="No such temperature zone"
                    id="validation.noSuchTemperatureZone"
                />
            );
        }
    }

    return message;
}

export function getMessageForFieldError(err) {
    if (err.messageTranslationKey) {
        return (
            <FormattedMessage
                defaultMessage={err.message}
                id={err.messageTranslationKey || '-'}
                values={err.values}
            />
        );
    }

    return getTranslationByMessage(err.message);
}

export function processItemError(error) {
    if (error.response?.data?.extraData) {
        throw new SubmissionError({
            error: error.response.data,
            fields: error.response.data.extraData.reduce(
                (errors, err) => ({
                    ...errors,
                    [err.field]: getMessageForFieldError(err),
                }),
                {}
            ),
        });
    }
    return error;
}

export function fetchItem({
    apiCollectionName,
    apiVersion,
    useClientPrefix = true,
    itemActionName,
    timeout,
}) {
    return ({ id, clientId } = {}) =>
        apiRequestActionCreator({
            actionName: itemActionName,
            actionParams: { id },
            params: {
                timeout: timeout || defaultTimeout,
                url: useClientPrefix
                    ? `/stateful/${
                          apiVersion || 'v1'
                      }/clients/${clientId}/${apiCollectionName}/${id}`
                    : `/stateful/${apiVersion || 'v1'}/${apiCollectionName}/${id}`,
            },
        });
}

export function updateItem({
    apiCollectionName,
    apiVersion,
    useClientPrefix = true,
    itemActionName,
    paramsFunction,
    timeout,
    errorFunction,
}) {
    return ({ id, clientId, ...params } = {}) =>
        apiRequestActionCreator({
            actionName: itemActionName,
            actionParams: { id },
            params: {
                data: paramsFunction ? paramsFunction({ params }) : params,
                method: 'put',
                timeout: timeout || defaultTimeout,
                url: useClientPrefix
                    ? `/stateful/${
                          apiVersion || 'v1'
                      }/clients/${clientId}/${apiCollectionName}/${id}`
                    : `/stateful/${apiVersion || 'v1'}/${apiCollectionName}/${id}`,
            },
            processError: error => processItemError(errorFunction ? errorFunction(error) : error),
            processSuccessResponse: () => id,
        });
}

export function addItem({
    apiCollectionName,
    apiVersion,
    useClientPrefix = true,
    itemActionName,
    paramsFunction,
    timeout,
    errorFunction,
}) {
    return ({ clientId, ...params } = {}) =>
        apiRequestActionCreator({
            actionName: itemActionName,
            params: {
                data: paramsFunction ? paramsFunction({ params }) : params,
                method: 'post',
                timeout: timeout || defaultTimeout,
                url: useClientPrefix
                    ? `/stateful/${apiVersion || 'v1'}/clients/${clientId}/${apiCollectionName}`
                    : `/stateful/${apiVersion || 'v1'}/${apiCollectionName}`,
            },
            processError: error => processItemError(errorFunction ? errorFunction(error) : error),
            processSuccessResponse: ({ data }) => ({
                id: data.id,
            }),
        });
}

export function deleteItemFieldValue({
    apiCollectionName,
    apiVersion,
    useClientPrefix = true,
    itemActionName,
    paramsFunction,
    timeout,
}) {
    return ({ id, clientId, fieldName, ...params } = {}) =>
        apiRequestActionCreator({
            actionName: `DELETE_${itemActionName}_FIELD`,
            actionParams: { id },
            params: {
                data: paramsFunction ? paramsFunction({ params }) : params,
                method: 'delete',
                timeout: timeout || defaultTimeout,
                url: useClientPrefix
                    ? `/stateful/${
                          apiVersion || 'v1'
                      }/clients/${clientId}/${apiCollectionName}/${id}/${fieldName}`
                    : `/stateful/${apiVersion || 'v1'}/${apiCollectionName}/${id}/${fieldName}`,
            },
        });
}

export function importCollection({
    apiCollectionName,
    apiVersion,
    collectionActionName,
    paramsFunction,
    timeout,
}) {
    return ({ clientId, ...params } = {}) =>
        apiRequestActionCreator({
            actionName: collectionActionName,
            params: {
                data: paramsFunction ? paramsFunction({ params }) : params,
                method: 'post',
                timeout: timeout || defaultTimeout,
                url: `/stateful/${
                    apiVersion || 'v1'
                }/clients/${clientId}/${apiCollectionName}/import`,
            },
        });
}

export function exportCollection({
    apiCollectionName,
    apiVersion,
    collectionActionName,
    exportDataToArrayOfArrays,
    exportFileName,
    paramsFunction,
    timeout,
}) {
    return ({ clientId, ...params } = {}) =>
        apiRequestActionCreator({
            actionName: collectionActionName,
            params: {
                params: paramsFunction ? paramsFunction({ params }) : params,
                timeout: timeout || defaultTimeout,
                url: `/stateful/${
                    apiVersion || 'v1'
                }/clients/${clientId}/${apiCollectionName}/export`,
            },
            processSuccessResponse: ({ data }) => {
                /* convert state to workbook */
                const ws = XLSX.utils.aoa_to_sheet(exportDataToArrayOfArrays(data));
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'SpreadSheet');
                /* generate XLSX file and send to client */
                XLSX.writeFile(wb, `${exportFileName}.xlsx`);
            },
        });
}
