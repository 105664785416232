/* eslint-disable import/prefer-default-export */

const getMatchingGroupFromExpression = (inputString, exp) => {
    try {
        const matches = inputString.match(new RegExp(exp));
        if (matches?.[1]) {
            return matches[1];
        }
    } catch (e) {
        // err
    }
    return null;
};

export function routeChanged(newRoute) {
    return (dispatch, getState) => {
        const {
            view: { clientId: prevClientId },
        } = getState();
        const clientIdString =
            newRoute && getMatchingGroupFromExpression(newRoute, /clients\/(\d+)/);
        const clientId = clientIdString ? Number(clientIdString) : null;

        if (clientId !== prevClientId) {
            dispatch({
                payload: {
                    clientId,
                },
                type: 'CLIENT_CHANGE_SUCCESS',
            });
        }
    };
}
