const initialState = [];

function reducer(state = initialState, action) {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
        return initialState;
    }

    if (action.type === 'CLIENT_API_SERVICES_SUCCESS') {
        return action.payload;
    }

    return state;
}

export default reducer;
