import { reject } from 'lodash';

const initialState = {
    failure: [],
    request: [],
    success: [],
};

function reducer(state = initialState, action) {
    if (
        action.type.slice(-8) === '_REQUEST' &&
        action.type !== 'LAYOUT_REQUEST' &&
        action.type !== 'OPEN_SLOTS_REQUEST' &&
        action.type !== 'SAVE_SLOT_STATUS_REQUEST'
    ) {
        return { ...state, request: [...state.request, action.requestParams] };
    }

    if (
        action.type.slice(-8) === '_FAILURE' &&
        action.type !== 'LAYOUT_FAILURE' &&
        action.type !== 'OPEN_SLOTS_FAILURE' &&
        action.type !== 'SAVE_SLOT_STATUS_FAILURE'
    ) {
        return {
            ...state,
            failure: [...state.failure, action.requestParams],
            request: reject(state.request, action.requestParams),
        };
    }

    if (
        action.type.slice(-8) === '_SUCCESS' &&
        action.type !== 'LAYOUT_SUCCESS' &&
        action.type !== 'OPEN_SLOTS_SUCCESS' &&
        action.type !== 'SAVE_SLOT_STATUS_SUCCESS'
    ) {
        return {
            ...state,
            request: reject(state.request, action.requestParams),
            success: [...state.failure, action.requestParams],
        };
    }

    return state;
}

export default reducer;
