import { getTranslationByMessage } from '../actions/collectionActions';

const initialState = {};

function reducer(state = initialState, action) {
    if (
        action.type === 'AUTHENTICATE_REQUEST' ||
        action.type === 'LOGOUT_REQUEST' ||
        action.type === 'ORDERS_IMPORT_RESET'
    ) {
        return initialState;
    }

    if (action.type === 'ORDERS_IMPORT_REQUEST' || action.type === 'ORDERS_IMPORT_START') {
        return { loading: true };
    }

    if (action.type === 'ORDERS_IMPORT_FAILURE' || action.type === 'ORDERS_IMPORT_SUCCESS') {
        return {
            ...action.payload,
            ...(action.payload.failures && {
                failures: action.payload.failures.map(failure => ({
                    ...failure,
                    errors: failure.errors.map(error => ({
                        ...error,
                        message:
                            error?.message?.id || error.messageTranslationKey
                                ? {
                                      id: error?.message?.id || error.messageTranslationKey,
                                      values: error?.message?.values || error.values,
                                  }
                                : getTranslationByMessage(error.message),
                    })),
                })),
            }),
        };
    }

    return state;
}

export default reducer;
