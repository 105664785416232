import { AppDrawer, CleverOfficeLogo } from 'cleveron-web-components';
import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleDarkMode as toggleDarkModeAction } from '../../actions/darkMode';
import { closeMenu as closeMenuAction, openMenu as openMenuAction } from '../../actions/menu';
import { closeSnackbar as closeSnackbarAction } from '../../actions/snackbar';
import AccountMenu from '../AccountMenu';
import LocaleSelector from '../LocaleSelector';
import MainMenu from '../MainMenu';
import CleverOfficeBackground from '../MainMenu/cleveroffice-bg.png';

function ConnectedAppDrawer({
    closeMenu,
    closeSnackbar,
    darkMode,
    id,
    authorities,
    user,
    menu,
    openMenu,
    snackbar,
    toggleDarkMode,
    version,
}) {
    return (
        <AppDrawer
            AccountMenu={forwardRef(() => (
                <AccountMenu closeMenu={closeMenu} id={`${id}AccountMenu`} />
            ))}
            backgroundImage={CleverOfficeBackground}
            closeAccountMenu={() => closeMenu('account')}
            closeMainMenu={() => closeMenu('main')}
            closeSnackbar={closeSnackbar}
            darkMode={darkMode || user?.superuser}
            drawer={user}
            id={id}
            isAccountMenuOpen={menu.account}
            isMainMenuOpen={menu.main}
            isSnackbarOpen={snackbar.open}
            Logo={CleverOfficeLogo}
            MainMenu={() => <MainMenu authorities={authorities} id={`${id}MainMenu`} user={user} />}
            openAccountMenu={() => openMenu('account')}
            openMainMenu={() => openMenu('main')}
            snackbarProps={snackbar}
            toggleDarkMode={toggleDarkMode}
            toolbarElements={<LocaleSelector />}
            version={version}
        />
    );
}

function mapStateToProps({ darkMode, me: { authorities, user }, menu, snackbar, version }) {
    return { authorities, darkMode, menu, snackbar, user, version };
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeMenu: closeMenuAction,
            closeSnackbar: closeSnackbarAction,
            openMenu: openMenuAction,
            toggleDarkMode: toggleDarkModeAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAppDrawer);
