import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { StatusPage } from 'cleveron-web-components';
import React from 'react';
import { defineMessage } from 'react-intl';

function Error404({ id }) {
    return (
        <StatusPage
            buttonLink="/"
            buttonTextKey={
                defineMessage({
                    defaultMessage: 'Go to front page',
                    id: 'error.action.goToFrontPage',
                }).id
            }
            Icon={ErrorOutlineIcon}
            id={id}
            textTopKey={
                defineMessage({
                    defaultMessage: 'We could not find this page.',
                    id: 'error.notFound.helper',
                }).id
            }
            titleKey={
                defineMessage({
                    defaultMessage: 'Not found',
                    id: 'error.notFound.title',
                }).id
            }
        />
    );
}

export default Error404;
