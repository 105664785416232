const initialState = [];

const sortedPermissions = [
    6, 32, 33, 12, 29, 9, 8, 10, 16, 34, 27, 13, 15, 7, 24, 26, 25, 22, 20, 30, 11, 23, 17, 3, 21,
    2, 1, 14, 28, 4, 5, 19, 18,
];

function permissions(state = initialState, action) {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
        return initialState;
    }

    if (action.type === 'PERMISSIONS_SUCCESS') {
        return action.payload.sort((a, b) =>
            sortedPermissions.includes(a.id) && sortedPermissions.includes(b.id)
                ? sortedPermissions.indexOf(a.id) - sortedPermissions.indexOf(b.id)
                : 1
        );
    }

    return state;
}

function clientPermissions(state = initialState, action) {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
        return initialState;
    }

    if (action.type === 'CLIENT_PERMISSIONS_SUCCESS') {
        return action.payload.sort(
            (a, b) => sortedPermissions.indexOf(a.id) - sortedPermissions.indexOf(b.id)
        );
    }

    return state;
}

export { clientPermissions, permissions };
