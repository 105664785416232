/* eslint-disable no-underscore-dangle */

import axios from 'axios';
import { applyMiddleware, compose, createStore } from 'redux';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import thunk from 'redux-thunk';

import reducer from '../reducers';
// eslint-disable-next-line import/no-cycle
import updateSession from './middlewares/updateSession';

export const axiosConfig = {
    baseURL:
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_PUBLIC_API_URL
            : window.REACT_APP_PUBLIC_API_URL,
    timeout: 60 * 1000,
};

const stateSyncConfig = {
    whitelist: ['AUTHENTICATE_SUCCESS', 'LOGOUT_REQUEST', 'ME_SUCCESS', 'TOGGLE_DARK_MODE'],
};

let middlewares = [
    thunk.withExtraArgument({
        axios: axios.create(axiosConfig),
    }),
];

// Adds middlewares to Client side only
if (typeof window !== 'undefined' && window.document?.createElement) {
    middlewares = [...middlewares, updateSession, createStateSyncMiddleware(stateSyncConfig)];
}

const configureStore = initialState => {
    const enhancers = compose(
        // Middleware store enhancer.
        applyMiddleware(
            // Initialising redux-thunk with extra arguments will pass the below
            // arguments to all the redux-thunk actions. Below we are passing a
            // preconfigured axios instance which can be used to fetch data with.
            // @see https://github.com/gaearon/redux-thunk
            ...middlewares
        ),
        // Redux Dev Tools store enhancer.
        // @see https://github.com/zalmoxisus/redux-devtools-extension
        // We only want this enhancer enabled for development and when in a browser
        // with the extension installed.
        process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined' &&
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
            ? // Call the brower extension function to create the enhancer.
              window.__REDUX_DEVTOOLS_EXTENSION__()
            : // Else we return a no-op function.
              f => f
    );

    const store = initialState
        ? createStore(reducer, initialState, enhancers)
        : createStore(reducer, enhancers);

    if (process.env.NODE_ENV === 'development' && module.hot) {
        // Enable Webpack hot module replacement for reducers. This is so that we
        // don't lose all of our current application state during hot reloading.
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers').default;

            store.replaceReducer(nextRootReducer);
        });
    }
    initMessageListener(store);
    return store;
};

// NOTE: If we create an '/api' endpoint in our application then we will neeed to
// configure the axios instances so that they will resolve to the proper URL
// endpoints on the server. We have to provide absolute URLs for any of our
// server bundles. To do so we can set the default 'baseURL' for axios. Any
// relative path requests will then be appended to the 'baseURL' in order to
// form an absolute URL.
// We don't need to worry about this for client side executions, relative paths
// will work fine there.
// Example:
//
// const axiosConfig = process.env.IS_NODE === true
//   ? { baseURL: process.env.NOW_URL || notEmpty(process.env.SERVER_URL) }
//   : {};
//
// Then we will then have to initialise our redux-thunk middlware like so:
//
// thunk.withExtraArgument({
//   axios: axios.create(axiosConfig),
// })

export default configureStore;
