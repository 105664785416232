const initialState = null;

function reducer(state = initialState, action) {
    if (action.type === 'RESET_USER_ACTION_TOKEN') {
        return initialState;
    }

    if (action.type === 'USER_ACTION_TOKEN_SUCCESS') {
        const { token, type } = action.requestParams?.data || {};
        return {
            error: null,
            type,
            value: token,
        };
    }

    if (action.type === 'USER_ACTION_TOKEN_FAILURE') {
        const { token, type } = action.requestParams?.data || {};
        return {
            error: action.payload?.response?.data || 'unknown',
            type,
            value: token,
        };
    }

    return state;
}

export default reducer;
