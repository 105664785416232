const initialState = {
    error: null,
};

function reducer(state = initialState, action) {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
        return initialState;
    }
    if (action.type === 'AUTHENTICATE_SUCCESS') {
        return { ...state, error: null };
    }
    if (['AUTHENTICATE_FAILURE', 'ME_FAILURE'].includes(action.type)) {
        return { ...state, error: action.payload };
    }
    if (action.type === 'UNAUTHENTICATED') {
        return initialState;
    }
    if (action.type === 'ME_SUCCESS') {
        return {
            ...state,
            error: null,
            ...action.payload,
            // eslint-disable-next-line sort-keys
            authorities:
                action.payload?.authorities?.reduce(
                    (acc, cur) => ({
                        ...acc,
                        [cur.client.id]: {
                            ...cur,
                            permissions: cur.permissions?.reduce(
                                (permissionMap, permissionDescriptor) => ({
                                    ...permissionMap,
                                    [permissionDescriptor.permissionId]: {
                                        ...permissionDescriptor,
                                        restrictedToCompanies:
                                            (permissionDescriptor?.restrictedToCompanies?.length &&
                                                permissionDescriptor.restrictedToCompanies) ||
                                            null,
                                    },
                                }),
                                {}
                            ),
                        },
                    }),
                    {}
                ) || {},
        };
    }

    return state;
}

export default reducer;
