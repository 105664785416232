const initialState = {
    account: false,
    main: false,
};

function reducer(state = initialState, action) {
    if (action.type === 'OPEN_MENU') {
        return { ...state, [action.payload]: true };
    }
    if (action.type === 'CLOSE_MENU') {
        return { ...state, [action.payload]: false };
    }
    return state;
}

export default reducer;
