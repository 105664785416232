const initialState = localStorage.getItem('darkMode');

function reducer(state = initialState, action) {
    if (action.type === 'TOGGLE_DARK_MODE') {
        if (state) {
            localStorage.removeItem('darkMode');
        } else {
            localStorage.setItem('darkMode', 'enabled');
        }
        return !state;
    }

    return state;
}

export default reducer;
