import { CloudOff as CloudOffIcon } from '@mui/icons-material';
import { StatusPage, withJob } from 'cleveron-web-components';
import React from 'react';
import { defineMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { closeSnackbar as closeSnackbarAction } from '../../../actions/snackbar';

function Error408({ id, route }) {
    return (
        <StatusPage
            buttonLink={route ? `/?redirectRoute=${route}` : '/?redirectRoute=/'}
            buttonTextKey={
                defineMessage({ defaultMessage: 'Try again', id: 'error.action.tryAgain' }).id
            }
            Icon={CloudOffIcon}
            id={id}
            textBottomKey={
                defineMessage({
                    defaultMessage: 'Please check your connection.',
                    id: 'error.timeout.helper.secondary',
                }).id
            }
            textTopKey={
                defineMessage({
                    defaultMessage: 'Could not establish a connection to the cloud.',
                    id: 'error.timeout.helper.primary',
                }).id
            }
            titleKey={defineMessage({ defaultMessage: 'Timed out', id: 'error.timeout.title' }).id}
        />
    );
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeSnackbar: closeSnackbarAction,
        },
        dispatch
    );

export default compose(
    connect(null, mapDispatchToProps),
    withJob({
        LoadingComponent: Error408,
        work: ({ closeSnackbar }) => closeSnackbar(),
    })
)(Error408);
