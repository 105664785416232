const initialState = [];

const SLOT_MEASUREMENTS_REGEX = /(\d+)_(\d+)_(\d+)$/g;

const slotTypeHasMeasurements = type => type?.match(SLOT_MEASUREMENTS_REGEX);

const getTypeMeasurements = type => {
    if (slotTypeHasMeasurements(type)) {
        const [width, height, depth] = type.match(SLOT_MEASUREMENTS_REGEX)[0].split('_');
        return {
            depth: Number(depth),
            height: Number(height),
            width: Number(width),
        };
    }
    return {};
};

const getVolumeByType = type => {
    if (slotTypeHasMeasurements(type)) {
        return Object.values(getTypeMeasurements(type)).reduce((acc, side) => acc * side, 1);
    }
    return 0;
};

const compareSlotTypes = (type1, type2) => {
    if (type1.startsWith('L') && !type2.startsWith('L')) {
        return -1;
    }
    if (type2.startsWith('L') && !type1.startsWith('L')) {
        return 1;
    }

    return getVolumeByType(type1) - getVolumeByType(type2);
};

function slotTypes(state = initialState, action) {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
        return initialState;
    }

    if (action.type === 'SLOT_TYPES_SUCCESS') {
        return action.payload
            .sort((slotType1, slotType2) => compareSlotTypes(slotType1.id, slotType2.id))
            .map(slotType => ({
                ...slotType,
                ...getTypeMeasurements(slotType.id),
            }));
    }

    return state;
}

function clientSlotTypes(state = initialState, action) {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
        return initialState;
    }

    if (action.type === 'CLIENT_SLOT_TYPES_SUCCESS') {
        return action.payload
            .sort((clientSlotType1, clientSlotType2) =>
                compareSlotTypes(clientSlotType1.type, clientSlotType2.type)
            )
            .map(clientSlotType => ({
                ...clientSlotType,
                ...getTypeMeasurements(clientSlotType.type),
            }));
    }

    return state;
}

export { clientSlotTypes, slotTypes };
