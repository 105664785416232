import React from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import configureStore from '../redux/configureStore';
import ConnectedProviders from './ConnectedProviders';

const store = configureStore();

function App() {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>CleverOffice</title>
                <meta content="CleverOffice" name="application-name" />
                <meta content="CleverOffice by Cleveron" name="description" />
                <meta charSet="utf-8" />
                <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
                <meta content="width=device-width, initial-scale=1" name="viewport" />
                <meta content="#222222" name="msapplication-TileColor" />
                <meta content="/favicons/mstile-144x144.png" name="msapplication-TileImage" />
                <meta content="#222222" name="theme-color" />
                <link
                    href="https://fonts.googleapis.com/css?family=Roboto:400,500,700"
                    rel="stylesheet"
                />
                {/*
                A great reference for favicons:
                https://github.com/audreyr/favicon-cheat-sheet
                It's a pain to manage/generate them. I run both these in order,
                and combine their results:
                http://realfavicongenerator.net/
                http://www.favicomatic.com/
                */}
                <link
                    href="/favicons/apple-touch-icon-152x152.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="152x152"
                />
                <link
                    href="/favicons/apple-touch-icon-144x144.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="144x144"
                />
                <link
                    href="/favicons/apple-touch-icon-120x120.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="120x120"
                />
                <link
                    href="/favicons/apple-touch-icon-114x114.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="114x114"
                />
                <link
                    href="/favicons/apple-touch-icon-76x76.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="76x76"
                />
                <link
                    href="/favicons/apple-touch-icon-72x72.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="72x72"
                />
                <link
                    href="/favicons/apple-touch-icon-57x57.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="57x57"
                />
                <link
                    href="/favicons/apple-touch-icon-60x60.png"
                    rel="apple-touch-icon-precomposed"
                    sizes="60x60"
                />
                <link
                    href="/favicons/apple-touch-icon-180x180.png"
                    rel="apple-touch-icon"
                    sizes="180x180"
                />
                <link color="#222222" href="/favicons/safari-pinned-tab.svg" rel="mask-icon" />
                <link
                    href="/favicons/favicon-196x196.png"
                    rel="icon"
                    sizes="196x196"
                    type="image/png"
                />
                <link
                    href="/favicons/favicon-128.png"
                    rel="icon"
                    sizes="128x128"
                    type="image/png"
                />
                <link
                    href="/favicons/favicon-96x96.png"
                    rel="icon"
                    sizes="96x96"
                    type="image/png"
                />
                <link
                    href="/favicons/favicon-32x32.png"
                    rel="icon"
                    sizes="32x32"
                    type="image/png"
                />
                <link href="/favicon.ico" rel="icon" sizes="16x16 32x32" />
                <meta content="#222222" name="msapplication-TileColor" />
                <meta content="/favicons/mstile-144x144.png" name="msapplication-TileImage" />
                <meta content="/favicons/mstile-70x70.png" name="msapplication-square70x70logo" />
                <meta
                    content="/favicons/mstile-150x150.png"
                    name="msapplication-square150x150logo"
                />
                <meta content="/favicons/mstile-310x150.png" name="msapplication-wide310x150logo" />
                <meta
                    content="/favicons/mstile-310x310.png"
                    name="msapplication-square310x310logo"
                />
                <link href="/manifest.json" rel="manifest" />
            </Helmet>
            <Provider store={store}>
                <Router>
                    <Route path="/" render={() => <ConnectedProviders id="app" />} />
                </Router>
            </Provider>
        </>
    );
}

export default App;
