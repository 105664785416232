import { isoStringToDateWithoutTimezone } from 'cleveron-web-components/utils';
import { addDays, addHours, addMonths } from 'date-fns';

import { INTERVALS } from '../constants';

function normalizeData({ apiDataFieldName, data }) {
    let normalizedData = [];
    if (data?.[apiDataFieldName]) {
        const dataFields = Array.isArray(data[apiDataFieldName])
            ? {
                  [apiDataFieldName]: data[apiDataFieldName],
              }
            : data[apiDataFieldName];
        const seriesData = Object.keys(dataFields).reduce(
            (acc, series) =>
                !dataFields[series].every(value => value === 0)
                    ? { ...acc, [series]: dataFields[series] }
                    : acc,
            {}
        );
        const seriesNames = Object.keys(seriesData);
        normalizedData.push(['interval', ...seriesNames]);
        let datapointPosition = isoStringToDateWithoutTimezone(data.queryParams.startTime);
        let lastNonZeroValuePosition;
        let currentTimestampPosition;
        const lastDatapointPosition = isoStringToDateWithoutTimezone(data.queryParams.endTime);

        for (let i = 0; lastDatapointPosition - datapointPosition > 0; i += 1) {
            const dataRow = [];
            dataRow.push(datapointPosition);
            // eslint-disable-next-line no-loop-func
            seriesNames.forEach(series => {
                const value = seriesData[series][i];
                if (typeof value !== 'undefined') {
                    dataRow.push(seriesData[series][i]);
                    if (value !== 0) {
                        lastNonZeroValuePosition = i + 2;
                    }
                } else {
                    dataRow.push(0);
                }
            });
            normalizedData.push(dataRow);
            if (datapointPosition > new Date() && !currentTimestampPosition) {
                currentTimestampPosition = i + 1;
            }
            if (data.queryParams.interval === INTERVALS.HOUR.id) {
                datapointPosition = addHours(datapointPosition, 1);
            } else if (data.queryParams.interval === INTERVALS.DAY.id) {
                datapointPosition = addDays(datapointPosition, 1);
            } else {
                datapointPosition = addMonths(datapointPosition, 1);
            }
        }
        if (currentTimestampPosition) {
            normalizedData = normalizedData.slice(
                0,
                Math.max(currentTimestampPosition, lastNonZeroValuePosition || 0)
            );
        }
    }
    return normalizedData;
}

function statisticsReducer({ apiDataFieldName, statisticsActionName }) {
    const initialState = [];

    return function reducer(state = initialState, action) {
        if (action.type === 'LOGOUT_REQUEST' || action.type === 'AUTHENTICATE_REQUEST') {
            return initialState;
        }

        if (action.type === `${statisticsActionName}_RESET`) {
            return initialState;
        }

        if (action.type === `${statisticsActionName}_SUCCESS`) {
            return normalizeData({
                apiDataFieldName,
                data: action.payload,
            });
        }

        return state;
    };
}

export default statisticsReducer;
