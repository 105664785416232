import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import apiServices from './apiServices';
import apmLayout from './apmLayout';
import apmSlots from './apmSlots';
import apmTypes from './apmTypes';
import authenticated from './authenticated';
import clientApiKeys from './clientApiKeys';
import clientApiServices from './clientApiServices';
import administratorRoles from './collections/administratorRoles';
import administrators from './collections/administrators';
import apmGroups from './collections/apmGroups';
import apmLanguagePacks from './collections/apmLanguagePacks';
import apmLanguages from './collections/apmLanguages';
import apmLocations from './collections/apmLocations';
import apmRoles from './collections/apmRoles';
import apms from './collections/apms';
import apmServiceHours from './collections/apmServiceHours';
import apmThemes from './collections/apmThemes';
import clientGroups from './collections/clientGroups';
import clients from './collections/clients';
import companies from './collections/companies';
import mailroomEvents from './collections/mailroom';
import mailrooms from './collections/mailrooms';
import officeRoles from './collections/officeRoles';
import orders from './collections/orders';
import reminderSchedules from './collections/reminderSchedules';
import slotGroups from './collections/slotGroups';
import storageApmEvents from './collections/storageApm';
import storageApms from './collections/storageApms';
import templates from './collections/templates';
import users from './collections/users';
import contexts from './contexts';
import darkMode from './darkMode';
import groupedSlots from './groupedSlots';
import locale from './locale';
import locales from './locales';
import locationSlots from './locationSlots';
import me from './me';
import menu from './menu';
import modal from './modal';
import notificationGateways from './notificationGateways';
import ordersImport from './ordersImport';
import orderTypes from './orderTypes';
import patternSettings from './patternSettings';
import patternTypes from './patternTypes';
import { clientPermissions, permissions } from './permissions';
import privacyPolicy from './privacyPolicy';
import requests from './requests';
import reservedSlots from './reservedSlots';
import slotsLimits from './slotsLimits';
import { clientSlotTypes, slotTypes } from './slotTypes';
import snackbar from './snackbar';
import averageParcelHeights from './statistics/averageParcelHeights';
import averageTimeInApm from './statistics/averageTimeInApm';
import events from './statistics/events';
import templateTypes from './templateTypes';
import userActionToken from './userActionToken';
import usersImport from './usersImport';
import version from './version';
import view from './view';

const rootReducer = combineReducers({
    administratorRoles,
    administrators,
    apiServices,
    apmGroups,
    apmLanguagePacks,
    apmLanguages,
    apmLayout,
    apmLocations,
    apmRoles,
    apms,
    apmServiceHours,
    apmSlots,
    apmThemes,
    apmTypes,
    authenticated,
    averageParcelHeights,
    averageTimeInApm,
    clientApiKeys,
    clientApiServices,
    clientGroups,
    clientPermissions,
    clients,
    clientSlotTypes,
    companies,
    contexts,
    darkMode,
    events,
    groupedSlots,
    locale,
    locales,
    locationSlots,
    mailroomEvents,
    mailrooms,
    me,
    menu,
    modal,
    notificationGateways,
    officeRoles,
    orders,
    ordersImport,
    orderTypes,
    patternSettings,
    patternTypes,
    permissions,
    privacyPolicy,
    reminderSchedules,
    requests,
    reservedSlots,
    slotGroups,
    slotsLimits,
    slotTypes,
    snackbar,
    storageApmEvents,
    storageApms,
    templates,
    templateTypes,
    userActionToken,
    users,
    usersImport,
    version,
    view,
});

export default withReduxStateSync(rootReducer);
