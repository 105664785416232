const initialState = {
    lastReset: null,
    open: false,
    readable: false,
    translations: [],
};

function reducer(state = initialState, action) {
    if (action.type === 'PRIVACY_POLICY_SUCCESS') {
        return {
            ...state,
            readable: !!action.payload?.length,
            translations: action.payload,
        };
    }

    if (action.type === 'LAST_PRIVACY_POLICY_ACCEPT_RESET_SUCCESS') {
        return {
            ...state,
            lastReset: action.payload.lastResetAt,
            readable: false,
        };
    }

    if (action.type === 'PRIVACY_POLICY_OPEN') {
        return {
            ...state,
            open: true,
        };
    }

    if (action.type === 'PRIVACY_POLICY_CLOSE') {
        return {
            ...state,
            open: false,
        };
    }

    return state;
}

export default reducer;
