import {
    AccountCircle as AccountCircleIcon,
    BusinessCenter as BusinessCenterIcon,
    ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';
import { MenuLink } from 'cleveron-web-components';
import React from 'react';
import { defineMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { closeMenu as closeMenuAction } from '../../actions/menu';
import usePermissions from '../usePermissions';

function AccountMenu({ authorities, closeMenu, id, user }) {
    const location = useLocation();
    const { isAdministrator, isSuperuser } = usePermissions();
    const [, path, clientIdString] = location.pathname.split('/');
    const clientId = path === 'clients' && parseInt(clientIdString, 10);
    const authority = authorities[clientId];
    const handleClose = () => closeMenu('account');

    return (
        <>
            <MenuLink
                closeMenu={handleClose}
                disabled={isAdministrator || isSuperuser}
                Icon={AccountCircleIcon}
                id={`${id}UserButton`}
                primaryText={user?.name}
                secondaryText={user?.email}
                to={`/clients/${clientId}/users/${user?.id}`}
            />
            {!!clientId && (
                <MenuLink
                    closeMenu={handleClose}
                    disabled={!(isSuperuser && clientId)}
                    Icon={BusinessCenterIcon}
                    id={`${id}CompanyButton`}
                    primaryText={authority?.client?.name}
                    to={`/clients/${clientId}`}
                />
            )}
            <MenuLink
                closeMenu={handleClose}
                Icon={ExitToAppIcon}
                id={`${id}LogoutButton`}
                primaryTextKey={
                    defineMessage({ defaultMessage: 'Log out', id: 'actions.logOut' }).id
                }
                to="/logout"
            />
        </>
    );
}

function mapStateToProps({ clients, darkMode, me: { authorities, user }, menu, snackbar }) {
    return { authorities, clients: clients.collection, darkMode, menu, snackbar, user };
}

const mapDispatchToProps = dispatch => bindActionCreators({ closeMenu: closeMenuAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
