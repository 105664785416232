import { stringify } from 'query-string';

const searchParamsToQueryString = ({ offset, ...params }) =>
    stringify({
        ...params,
        page: offset + 1,
    });

function collectionReducer({
    apiCollectionName = 'collection',
    collectionActionName,
    defaultState,
    itemActionName,
}) {
    const initialState = {
        collection: {},
        ids: null,
        initial: true,
        total: 0,
        ...defaultState,
    };
    return function reducer(state = initialState, action) {
        if (
            action.type === 'LOGOUT_REQUEST' ||
            action.type === 'AUTHENTICATE_REQUEST' ||
            action.type === `${collectionActionName}_EMPTY` ||
            action.type === 'CLIENT_CHANGE_SUCCESS'
        ) {
            return initialState;
        }
        if (action.type === `${collectionActionName}_SUCCESS`) {
            const collection = {};
            for (let i = 0; i < action.payload[apiCollectionName].length; i += 1) {
                const item = action.payload[apiCollectionName][i];
                collection[item.id] = {
                    ...state.collection[item.id],
                    ...item,
                    collectionUrl: action?.requestParams?.url,
                };
            }
            return {
                ...state,
                collection: {
                    ...state.collection,
                    ...collection,
                },
                error: undefined,
                hasMore: action.payload.hasMore,
                ids: action.payload[apiCollectionName].map(item => item.id),
                searchQuery: searchParamsToQueryString(action.requestParams.params),
                total: action.payload.totalCount,
            };
        }
        if (action.type === `${collectionActionName}_FAILURE`) {
            return {
                ...state,
                error: action.payload,
            };
        }

        if (
            action.type === `${itemActionName}_SUCCESS` &&
            action.requestParams &&
            action.requestParams.method !== 'put'
        ) {
            return {
                ...state,
                collection: {
                    ...state.collection,
                    [action.payload.id]: {
                        ...state.collection[action.payload.id],
                        ...action.payload,
                        error: undefined,
                        itemUrl: action?.requestParams?.url,
                    },
                },
                initial: false,
            };
        }

        if (
            action.type === `${itemActionName}_FAILURE` &&
            action.actionParams &&
            action.actionParams.id
        ) {
            const id = Number(action.actionParams.id);
            return {
                ...state,
                collection: {
                    ...state.collection,
                    [id]: {
                        ...(state.collection[id] || {}),
                        error: action.payload,
                    },
                },
                initial: false,
            };
        }

        return state;
    };
}

export default collectionReducer;
