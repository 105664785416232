import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { USER_TYPES } from '../constants';

export default function usePermissions(permissions = {}) {
    const location = useLocation();
    const [, , clientId] = location.pathname.split('/');
    const me = useSelector(state => state.me);
    const isSuperuser = me.user?.typeId === USER_TYPES.SUPERUSER;
    const isAdministrator = me.user?.typeId === USER_TYPES.ADMINISTRATOR;
    const authority = isAdministrator
        ? Object.values(me.authorities)?.[0]
        : me.authorities?.[clientId];

    const result = Object.keys(permissions).reduce((acc, curr) => {
        if (permissions[curr] === true) {
            return {
                ...acc,
                [curr]: {
                    authorized: true,
                },
            };
        }
        const permission = authority?.permissions?.[permissions[curr]];
        if (permission) {
            return {
                ...acc,
                [curr]: {
                    authorized: true,
                    restrictedToCompanies: permission.restrictedToCompanies,
                },
            };
        }
        return {
            ...acc,
            [curr]: false,
        };
    }, {});

    return {
        ...result,
        isAdministrator,
        isSuperuser,
    };
}
