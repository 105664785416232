import { hasValue } from 'cleveron-web-components/utils/validators';

import languagePack from './language-pack.json';

function toIntlLanguages(languages) {
    const defaultLanguage = languages.CleverLanguage;

    return Object.values(languages).reduce(
        (acc, { locale, translations }) => ({
            ...acc,
            [locale]: Object.entries(defaultLanguage.translations).reduce(
                (allKeys, [key]) => ({
                    ...allKeys,
                    [key]: hasValue(translations[key])
                        ? translations[key]
                        : defaultLanguage.translations[key],
                }),
                {}
            ),
        }),
        {}
    );
}

const intlLanguages = toIntlLanguages(languagePack);

export default intlLanguages;
