/* eslint-disable import/prefer-default-export */
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import { Collapse, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { emphasize } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

// TODO: move this to cleveron-web-components after full menu redesign

const useStyles = makeStyles(theme => ({
    container: {
        borderRadius: 4,
        color: theme.palette.primary.contrastText,
        fontWeight: 'normal',
        height: 40,
        marginLeft: 8,
        marginRight: 8,
        marginTop: 4,
        outline: 'none',
    },
    icon: {
        color: ({ active }) => [
            active ? theme.palette.primary.contrastText : 'rgba(255,255,255,0.54)',
        ],
        marginLeft: -6,
        minWidth: `${theme.spacing(4)} !important`,
    },
    iconElement: {
        fontSize: 20,
    },
    item: {
        '&:hover': {
            '& $icon': {
                color: theme.palette.primary.contrastText,
            },
            backgroundColor: emphasize('rgba(255, 255, 255, 0.12)', 0.08),
        },
        borderRadius: 4,
        color: theme.palette.primary.contrastText,

        margin: `0px 0px 0px ${theme.spacing(1)}`,
        padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
        textDecoration: 'none',
        width: 170,
    },
    items: {
        borderLeft: '1px solid rgba(255,255,255, 0.12)',
        marginLeft: theme.spacing(3),
        paddingLeft: theme.spacing(1),
    },
    label: {
        minWidth: 140,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    root: {
        padding: `${theme.spacing(1)} 0px ${theme.spacing(1)} 0px`,
    },
}));

const MainMenuGroup = ({ id, items, label, Icon }) => {
    const classes = useStyles();
    const [collapsed, setCollapsed] = useState(false);

    return (
        <MenuList className={classes.root}>
            <MenuItem
                className={classes.container}
                icon={OpenInNewIcon}
                id={`${id}GroupParent`}
                onClick={() => {
                    setCollapsed(!collapsed);
                }}
            >
                <ListItemIcon className={classes.icon} id={`${id}LinkIcon`}>
                    <Icon className={classes.iconElement} />
                </ListItemIcon>
                <ListItemText className={classes.label} id={`${id}LinkText`} primary={label} />
                {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </MenuItem>
            <Collapse className={classes.items} in={collapsed} timeout="auto" unmountOnExit>
                {items.map((item, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>{item}</React.Fragment>
                ))}
            </Collapse>
        </MenuList>
    );
};

const MainMenuGroupHyperLinkItem = ({ id, href, label, target = '_blank', ...other }) => {
    const classes = useStyles();
    return (
        <MenuItem
            className={classes.item}
            component="a"
            href={href}
            id={`${id}Link`}
            tabIndex={0}
            target={target}
            {...other}
        >
            {label}
        </MenuItem>
    );
};

export { MainMenuGroup, MainMenuGroupHyperLinkItem };
