import translations from '../translations';

function verifyLocale(locale) {
    return locale in translations ? locale : 'en-GB';
}

const initialState = verifyLocale(localStorage.getItem('locale'));

function reducer(state = initialState, action) {
    if (action.type === 'SELECT_LOCALE') {
        const locale = verifyLocale(action.locale);
        localStorage.setItem('locale', locale);

        return locale;
    }

    return state;
}

export default reducer;
