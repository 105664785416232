/* eslint-disable import/prefer-default-export */

import { defineMessage } from 'react-intl';

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchPrivacyPolicy() {
    return apiRequestActionCreator({
        actionName: 'PRIVACY_POLICY',
        params: {
            url: `/stateful/v1/privacy-policy`,
        },
    });
}

export function savePrivacyPolicy(data) {
    return apiRequestActionCreator({
        actionName: 'SAVE_PRIVACY_POLICY',
        params: {
            data,
            method: 'post',
            url: `/stateful/v1/privacy-policy`,
        },
    });
}

export function deletePrivacyPolicy(id) {
    return apiRequestActionCreator({
        actionName: 'DELETE_PRIVACY_POLICY',
        params: {
            method: 'delete',
            url: `/stateful/v1/privacy-policy/${id}`,
        },
        rethrow: true,
        successSnackbarProps: {
            message: {
                id: defineMessage({
                    defaultMessage: 'Deleted',
                    id: 'actions.deleted',
                }).id,
            },
        },
    });
}

export function resetPrivacyPoliyAccept() {
    return apiRequestActionCreator({
        actionName: 'RESET_PRIVACY_POLICY_ACCEPT',
        params: {
            data: {
                reset: true,
            },
            method: 'put',
            url: `/stateful/v1/privacy-policy/reset`,
        },
    });
}

export function fetchLastPrivacyPolicyReset() {
    return apiRequestActionCreator({
        actionName: 'LAST_PRIVACY_POLICY_ACCEPT_RESET',
        params: {
            method: 'get',
            url: `/stateful/v1/privacy-policy/reset`,
        },
    });
}

export function acceptPrivacyPolicy() {
    return apiRequestActionCreator({
        actionName: 'PRIVACY_POLICY_ACCEPT',
        params: {
            method: 'put',
            url: `/stateful/v1/me/privacy-policy`,
        },
        rethrow: true,
        successSnackbarProps: {
            message: null,
            open: false,
        },
    });
}

export function openPrivacyPolicy(payload) {
    return { payload, type: 'PRIVACY_POLICY_OPEN' };
}

export function closePrivacyPolicy(payload) {
    return { payload, type: 'PRIVACY_POLICY_CLOSE' };
}
