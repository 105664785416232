const initialState = true;

function reducer(state = initialState, action) {
    const notAuthenticatedActions = [
        'AUTHENTICATE_FAILURE',
        'AUTHENTICATE_REQUEST',
        'CHECK_AUTH_FAILURE',
        'LOGOUT_REQUEST',
        'UNAUTHENTICATED',
    ];

    if (notAuthenticatedActions.includes(action.type)) {
        return false;
    }

    if (action.type === 'AUTHENTICATE_SUCCESS' || action.type === 'ME_SUCCESS') {
        return true;
    }

    return state;
}

export default reducer;
