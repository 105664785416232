const initialState = {
    modalName: null,
    modalProps: {},
};

function reducer(state = initialState, action) {
    if (action.type === 'OPEN_MODAL') {
        return {
            modalName: action.payload.modalName,
            modalProps: action.payload.modalProps,
        };
    }
    if (action.type === 'CLOSE_MODAL') {
        return initialState;
    }
    return state;
}

export default reducer;
