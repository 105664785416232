const initialState = {
    companies: null,
    initialCompanies: [],
};

function reducer(state = initialState, action) {
    if (
        action.type === 'LOGOUT_REQUEST' ||
        action.type === 'AUTHENTICATE_REQUEST' ||
        action.type === 'SLOTS_LIMITS_REQUEST'
    ) {
        return initialState;
    }

    if (action.type === 'SLOTS_LIMITS_SUCCESS') {
        return { ...initialState, initialCompanies: action.payload };
    }

    if (action.type === 'SAVE_SLOTS_LIMITS_SUCCESS') {
        return { ...initialState, initialCompanies: action.requestParams.data };
    }

    if (action.type === 'SET_SLOTS_LIMITS') {
        return { ...state, companies: action.payload };
    }

    if (action.type === 'ENABLE_RESERVED_SLOTS' || action.type === 'DISABLE_RESERVED_SLOTS') {
        return { ...initialState, initialCompanies: state.initialCompanies };
    }

    return state;
}

export default reducer;
