/* eslint-disable no-param-reassign */
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import { Form, FormSpy, useForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

class SubmissionError extends Error {
    constructor({ error, fields }) {
        super('SubmissionError');
        this.name = 'SubmissionError';
        this.error = error;
        this.fields = fields;
    }
}

const setSubmitErrors = ([errors = {}], state) => {
    Object.keys(state.fields).forEach(field => {
        if (errors[field]) {
            state.fields[field].data = {
                ...state.fields[field].data,
                submitError: errors[field],
            };
        } else {
            state.fields[field].data = {
                ...state.fields[field].data,
                submitError: null,
            };
        }
    });
};

const clearSubmitError = ([field], state) => {
    if (state.fields[field]?.data.submitError) {
        state.fields[field].data = {
            ...state.fields[field].data,
            submitError: null,
        };
    }
};

const ReactFinalForm = ({
    children,
    onChanges = () => {},
    onSubmit,
    onSubmitFail = () => {},
    onSubmitSuccess = () => {},
    subscription = {},
    ...props
}) => {
    const dispatch = useDispatch();
    if (Object.keys(subscription).some(key => key === 'values')) {
        console.warn(
            'Using values in subscription will cause re-renders on every input change, use <FormSpy /> or useFormValues() instead!'
        );
    }
    return (
        <Form
            {...props}
            initialValuesEqual={isEqual}
            mutators={{ ...arrayMutators, clearSubmitError, setSubmitErrors }}
            onSubmit={async (values, formApi) => {
                const { mutators } = formApi;
                try {
                    const result = await onSubmit(values, dispatch, { ...props });
                    mutators.setSubmitErrors({});
                    onSubmitSuccess(result, dispatch);
                } catch (err) {
                    if (err instanceof SubmissionError) {
                        onSubmitFail(
                            {
                                error: err.error,
                                fields: err.fields,
                            },
                            dispatch
                        );
                        // NB! Default submitErrors are cleared on next submit
                        mutators.setSubmitErrors(err.fields);
                        return {
                            [FORM_ERROR]: err.error,
                            ...err.fields,
                        };
                    }

                    if (process.env.NODE_ENV !== 'production') {
                        console.error('Unexpected error while submitting form,', err);
                    }

                    onSubmitFail(err, dispatch);
                    return {
                        [FORM_ERROR]: err.error,
                    };
                }
                return undefined;
            }}
            subscription={{ pristine: true, submitting: true, ...props, ...subscription }}
            validateOnBlur={false}
        >
            {({ handleSubmit, submitting, form }) => {
                return (
                    <>
                        <FormSpy
                            onChange={({ values, dirtyFieldsSinceLastSubmit, submitErrors }) => {
                                if (dirtyFieldsSinceLastSubmit && submitErrors) {
                                    Object.keys(dirtyFieldsSinceLastSubmit).forEach(field =>
                                        form.mutators.clearSubmitError(field)
                                    );
                                }
                                onChanges({ change: form.change, values }, dispatch);
                            }}
                            subscription={{
                                dirtyFieldsSinceLastSubmit: true,
                                submitErrors: true,
                                values: true,
                            }}
                        />
                        {children({
                            ...props,
                            handleSubmit: e => {
                                // trigger submit from button onClick
                                if (form.getState().hasValidationErrors) {
                                    onSubmitFail(
                                        {
                                            error: {
                                                id: 11,
                                            },
                                        },
                                        dispatch
                                    );
                                }
                                return handleSubmit(e);
                            },
                            submit: () => {
                                // trigger submit inside code
                                if (form.getState().hasValidationErrors) {
                                    onSubmitFail(
                                        {
                                            error: {
                                                id: 11,
                                            },
                                        },
                                        dispatch
                                    );
                                }
                                return form.submit();
                            },
                            submitting,
                        })}
                    </>
                );
            }}
        </Form>
    );
};

const useFormValues = (fields = []) => {
    if (!Array.isArray(fields)) {
        throw new Error('fields have to by array');
    }
    const valuesRef = useRef({});
    const [values, setValues] = useState(
        fields.reduce((acc, field) => ({ ...acc, [field]: null }), {})
    );
    const form = useForm();
    useEffect(() => {
        const unsubscribe = form.subscribe(
            ({ values: formValues }) => {
                const changedValues = fields.reduce(
                    (acc, field) => ({ ...acc, [field]: formValues[field] }),
                    {}
                );
                if (!isEqual(valuesRef.current, changedValues)) {
                    valuesRef.current = changedValues;
                    setValues(changedValues);
                }
            },
            { values: true }
        );

        return () => {
            unsubscribe();
        };
    }, [form, fields]);
    return values;
};

const hasErrors = fieldErrors =>
    Object.values(fieldErrors || {}).some(error => error !== undefined && error !== null);

const useFormErrorFields = () => {
    const ref = useRef({ errorFields: [], fields: [], submitErrorFields: [] });
    const [fields, setFields] = useState([]);
    const form = useForm();
    useEffect(() => {
        const getAllFields = () =>
            form.getRegisteredFields().filter(
                (field, i, registeredFields) =>
                    // Omit parents of array fields (not actual fields)
                    !registeredFields.some(otherField => otherField.startsWith(`${field}[`))
            );
        const updateRef = ({
            errorFields = ref.current.errorFields,
            submitErrorFields = ref.current.submitErrorFields,
        }) => {
            ref.current.errorFields = errorFields;
            ref.current.submitErrorFields = submitErrorFields;
            const newFields = [...new Set([...errorFields, ...submitErrorFields])].sort();

            if (!isEqual(ref.current.fields, newFields)) {
                if (form.getState().submitFailed) {
                    ref.current.fields = newFields;
                    setFields(newFields);
                }
            }
        };

        const subscriptions = [
            // Overwrite submitErrorFields as soon as submitErrors changes (unless submitting)
            form.subscribe(
                ({ submitErrors }) => {
                    if (!form.getState().submitting && !form.getState().hasValidationErrors) {
                        const submitErrorFields = getAllFields().filter(field =>
                            // Error representation in obj { 'passwordPolicy.length': {} }
                            hasErrors(submitErrors?.[field])
                        );
                        updateRef({ submitErrorFields });
                    }
                },
                { submitErrors: true }
            ),
            // Overwrite errorFields as soon as errors changes
            form.subscribe(
                ({ errors }) => {
                    const errorFields = getAllFields().filter(field =>
                        // Error representation in obj { passwordPolicy: { length: {} } }
                        hasErrors(get(errors, field))
                    );
                    updateRef({ errorFields });
                },
                { errors: true }
            ),
            // Remove field from submitErrorFields as soon as it first becomes dirty
            form.subscribe(
                ({ dirtyFieldsSinceLastSubmit }) => {
                    const submitErrorFields = ref.current.submitErrorFields.filter(
                        field => !dirtyFieldsSinceLastSubmit[field]
                    );
                    updateRef({ submitErrorFields });
                },
                { dirtyFieldsSinceLastSubmit: true }
            ),
        ];

        return () => {
            subscriptions.forEach(unsubscribe => unsubscribe());
        };
    }, [form]);

    return fields;
};

const reactFinalFormHOC =
    ({ ...formProps } = {}) =>
    FormComponent =>
    ({ ...componentProps }) => {
        return (
            <ReactFinalForm {...componentProps} {...formProps}>
                {({ ...reactFinalFormProps }) => <FormComponent {...reactFinalFormProps} />}
            </ReactFinalForm>
        );
    };

export { ReactFinalForm, reactFinalFormHOC, SubmissionError, useFormValues, useFormErrorFields };
