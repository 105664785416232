import { MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setLocale } from '../../actions/locale';
import languagePack from '../../translations/language-pack.json';

const useStyles = makeStyles(theme => ({
    select: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0 !important',
        },
    },
    selected: {
        backgroundColor: `${theme.palette.action.selected} !important`,
    },
}));

function LocaleSelector({ currentLocale, onLocaleChange }) {
    const classes = useStyles();
    const localeDisplayNamePairs = useMemo(
        () =>
            Object.values(languagePack).reduce(
                (acc, { displayName, locale }) => ({ ...acc, [locale]: displayName }),
                {}
            ),
        []
    );

    return (
        <Select
            className={classes.select}
            onChange={event => onLocaleChange(event.target.value)}
            renderValue={value => localeDisplayNamePairs[value]}
            value={currentLocale}
            variant="outlined"
        >
            {Object.entries(localeDisplayNamePairs).map(([locale, displayName]) => {
                return (
                    <MenuItem key={locale} classes={{ selected: classes.selected }} value={locale}>
                        {displayName}
                    </MenuItem>
                );
            })}
        </Select>
    );
}

function mapStateToProps({ locale }) {
    return { currentLocale: locale };
}

const mapDispatchToProps = dispatch => bindActionCreators({ onLocaleChange: setLocale }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LocaleSelector);
