const initialState = [];

function reducer(state = initialState, action) {
    if (
        action.type === 'LOGOUT_REQUEST' ||
        action.type === 'AUTHENTICATE_REQUEST' ||
        action.type === 'LOCATION_SLOTS_REQUEST' ||
        action.type === 'LOCATION_SLOTS_RESET'
    ) {
        return initialState;
    }

    if (action.type === 'LOCATION_SLOTS_SUCCESS') {
        return action.payload;
    }

    return state;
}

export default reducer;
