import { defineMessage } from 'react-intl';

const initialState = {
    message: '',
    open: false,
    type: 'success',
};

function reducer(state = initialState, action) {
    if (action.type === 'SNACKBAR_CLOSE' || action.type === 'AUTHENTICATE_SUCCESS') {
        return { ...state, open: false };
    }
    if (action.type.slice(-15) === '_IMPORT_SUCCESS') {
        if (action.payload.failMessage || action.payload.fail > 0) {
            return {
                ...state,
                message: action.payload.failMessage || {
                    id: 'import.issues',
                    values: {
                        count: action.payload.fail,
                    },
                },
                open: true,
                type: 'error',
            };
        }
        return {
            ...state,
            message: {
                id: 'import.imported',
                values: {
                    count:
                        action.payload.insert + (!!action.payload.update && action.payload.update),
                },
            },
            open: true,
            type: 'success',
        };
    }
    if (
        action.type !== 'OPEN_SLOTS_SUCCESS' &&
        action.type !== 'SAVE_SLOT_STATUS_SUCCESS' &&
        action.type.slice(-8) === '_SUCCESS' &&
        action.requestParams &&
        ['put', 'post'].includes(action.requestParams.method)
    ) {
        return {
            ...state,
            message: defineMessage({ defaultMessage: 'Saved', id: 'actions.saved' }),
            open: true,
            type: 'success',
            ...action.snackbarProps,
        };
    }
    if (action.type.slice(-15) === '_IMPORT_FAILURE') {
        if (action.payload.failMessage || action.payload.fail > 0) {
            return {
                ...state,
                message: action.payload.failMessage || {
                    id: 'import.issues',
                    values: {
                        count: action.payload.fail,
                    },
                },
                open: true,
                type: 'error',
            };
        }
    }
    if (action.type === 'FORM_SUBMIT_FAILURE') {
        return { ...state, message: action.payload.message, open: true, type: 'error' };
    }
    if (
        action.type.slice(-8) === '_FAILURE' &&
        action.type !== 'LAYOUT_FAILURE' &&
        action.type !== 'OPEN_SLOTS_FAILURE' &&
        action.type !== 'SAVE_SLOT_STATUS_FAILURE' &&
        action.payload
    ) {
        const message =
            action.payload.response &&
            action.payload.response.data &&
            action.payload.response.data.code
                ? { id: `error.${action.payload.response.data.code}` }
                : defineMessage({
                      defaultMessage: 'Something unexpected happened.',
                      id: 'error.genericFailure',
                  });
        return { ...state, message, open: true, type: 'error' };
    }
    if (action.type === 'SNACKBAR_OPEN') {
        return {
            ...state,
            message: action.payload.message,
            open: true,
        };
    }

    if (action.type.startsWith('DELETE_') && action.type.slice(-8) === '_SUCCESS') {
        return { ...state, message: 'Reset', open: true, type: 'success', ...action.snackbarProps };
    }

    return state;
}

export default reducer;
