import { LoadingPage } from 'cleveron-web-components/components';
import Keycloak from 'keycloak-js';
import React, { createContext, useContext, useEffect, useState } from 'react';

const keycloakConfig = {
    clientId: 'cleveroffice',
    realm: 'cleveron',
    url:
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_KEYCLOAK_AUTH_URL
            : window.REACT_APP_KEYCLOAK_AUTH_URL,
};

export const keycloak = new Keycloak(keycloakConfig);

const AuthContext = createContext({
    authenticated: false,
});

export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthContextProvider({ children }) {
    const [authenticated, setAuthenticated] = useState();

    useEffect(() => {
        async function initializeKeycloak() {
            try {
                let kcAuthenticated = keycloak.authenticated;
                if (!keycloak.didInitialize) {
                    kcAuthenticated = await keycloak.init({
                        checkLoginIframe: false,
                        onLoad: 'login-required',
                    });
                }

                if (!kcAuthenticated) {
                    keycloak.login();
                }

                setAuthenticated(true);
            } catch (e) {
                console.error('error initializing Keycloak', e);
            }
        }

        initializeKeycloak();
    }, []);

    const logout = () => {
        keycloak.logout({ redirectUri: window.location.origin });
        setAuthenticated(false);
    };

    if (!authenticated) {
        return <LoadingPage />;
    }

    return (
        <AuthContext.Provider value={{ authenticated, logout }}>{children}</AuthContext.Provider>
    );
}
