const initialState = [];

function reducer(state = initialState, action) {
    const keepState = action?.requestActionProps?.keepState;
    if (
        action.type === 'LOGOUT_REQUEST' ||
        action.type === 'AUTHENTICATE_REQUEST' ||
        (action.type === 'GROUPED_SLOTS_REQUEST' && !keepState)
    ) {
        return initialState;
    }

    if (action.type === 'GROUPED_SLOTS_SUCCESS') {
        return action.payload;
    }

    return state;
}

export default reducer;
